import React, { useState, useEffect, createContext, useCallback } from "react";
import { type PaletteMode } from "@mui/material";


export function useCookie(key:string, initial:string) : [string, (value: string) => any] {
  const [value, setValue] = useState(
      document.cookie.split('; ').find(row => row.startsWith(`${key}=`))?.split('=')[1] ?? initial
  );

  function setCookie(value:string) {
      document.cookie = `${key}=${value}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=Lax;`;
  }


  useEffect(() => {
      setCookie(value);
  }, [value, key]);

  return [
      value,
      value => {
          setCookie(value);
          setValue(value);
      },
  ];
}









interface AppProviderProps {
  children: React.ReactNode
}

type AppContextType = {
  mode: PaletteMode
  setMode: (mode: PaletteMode) => void
 // config: AppConfig
}





export const AppContext = createContext<AppContextType>({} as AppContextType);

export const AppContextProvider = ({ children }: AppProviderProps) => {

  const [themeCookie, setThemeCookie] = useCookie('theme', 'light');
  const [mode, setMode] = useState<PaletteMode>(themeCookie as PaletteMode);


  useEffect(()=>{

    if (themeCookie !== mode) setThemeCookie(mode)
  },[mode])

  



  return (
    <AppContext.Provider value={{ mode, setMode }}>
      {children}
    </AppContext.Provider>
  );
};