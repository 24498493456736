import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
 
type GhTreeExandRadioProps = {
  value:boolean,
  setValue: (value:boolean)=>void
}

const  GhTreeExandRadio = (props:GhTreeExandRadioProps) =>{
  const {value, setValue} = props 

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value
   // console.log("[GhTreeExandRadio] handleChange ", value)
    setValue( value==="true"?true:false);
  };




  return (
    <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">Folders</FormLabel>
      <RadioGroup
      row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value={true} control={<Radio />} label="Expand" />
        <FormControlLabel value={false} control={<Radio />} label="Collapse" />
      </RadioGroup>
    </FormControl>
  );
}



export {GhTreeExandRadio}