
import { useState,useEffect } from "react"
import { MdFileWrapPaper } from "./MdFileWrapPaper";


import ReactMarkdown from "react-markdown";
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import rehypeHighlight from 'rehype-highlight'
import rehypeRaw from 'rehype-raw'
import rehypeSlug from 'rehype-slug'
import remarkGfm from 'remark-gfm'
import remarkToc from 'remark-toc'
import hljs from "highlight.js"


import { useAppContext } from "../../service/context/app/useAppContext";


import "./overrides-dark.css"
import "./overrides-light.css"


const MarkdownRender = ({text}:{text:string})=>{
    const rehypePlugins = [rehypeSlug, rehypeRaw, rehypeKatex]
    /** @type {PluggableList} */

    const { mode } = useAppContext()


    const remarkPlugins = [remarkToc, remarkGfm, remarkMath]
    return(
        <MdFileWrapPaper variant="outlined" className="article-paper-outlined" >
        <ReactMarkdown
          // ref={mdRootRef}

          //  data-theme={mode}
          //className={`[data-theme="${mode}"]`}
          className={`custom-md-root-${mode}`}
          remarkPlugins={remarkPlugins}
          rehypePlugins={rehypePlugins}
        >{text}
        </ReactMarkdown>
       </MdFileWrapPaper>
    )
}


export {MarkdownRender}