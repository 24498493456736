import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//import Divider from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Collapse from '@mui/material/Collapse';
import { Typography, Box } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';

import { GhRepoParserMachineContext } from "../github/ghrepomachine";
import { useSelector } from '@xstate/react';

import { GhTreeTypeSelectRadio } from './TreeTypeRadio'
import { GhTreeExandRadio } from './TreeExpandRadio'
import { type GhPPTreeType, TreeNode } from '../../../types/app.types';
import { MdFileWrapPaper } from '../../../components/markdown/MdFileWrapPaper';
//https://stackoverflow.com/a/2117523/592737
function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  ) as string;
}



const RecursiveExpandableListItem = ({ children, depth, primary, secondary, expanded }: { children: React.ReactNode, depth: number, primary: string, secondary: string, expanded: boolean }) => {
  const [open, setOpen] = React.useState(expanded);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>

      <ListItemButton onClick={handleClick} divider={true} sx={{
        pl: 2 * depth
      }}>
        <ListItemIcon>
          <KeyboardArrowRightIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary={primary}
          secondary={secondary}
          sx={{ whiteSpace: "nowrap", maxWidth: "100%" }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>


      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div"
          disablePadding

        >
          {/* <ListSubheader component="div">
            Inner {name} Items
          </ListSubheader> */}
          {children}


        </List>
      </Collapse>
    </>
  )
}







const RecursivePPTreeList = (props: {
  data: TreeNode[],
  depth?: number,
  expanded: boolean,
  setActiveDoc:(path:string)=>void
}) => {

  const { data, expanded, depth = 0 , setActiveDoc} = props


  //  data.sort((a,b)=>a.type === 'blob'?-1:1)
  return (
    <>
      {data.map(item => (
        <React.Fragment
          key={uuidv4()}
        >

          {item.children?.length ?
            <RecursiveExpandableListItem

              primary={item.path.split('/').pop() || "empty"}
              secondary={item.path}
              depth={depth}
              expanded={expanded}
            >
              <RecursivePPTreeList
                depth={depth + 1}
                data={item.children}
                expanded={expanded}
                setActiveDoc={setActiveDoc}
              />
            </RecursiveExpandableListItem> :

            <ListItemButton
            onClick={()=>setActiveDoc(item.path)}
              sx={{
                pl: 2 * depth
              }}
              color='primary'
              divider={true}
            >
              <ListItemIcon
             
              >
                {/* <KeyboardArrowRightIcon fontSize="small" /> */}
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={item.path.split('/').pop() || "empty"} secondary={item.path} sx={{ whiteSpace: "nowrap", maxWidth: "100%" }} />
            </ListItemButton>



          }

        </React.Fragment>

      ))}

    </>
  )
};


const TreeNodeSidebarDisplay = () => {
  //const [open, setOpen] = React.useState(true);

  const svc = GhRepoParserMachineContext.useActorRef()
  const files_tree = useSelector(svc, (state) => state.context.files_tree)
  const files_tree_compact = useSelector(svc, (state) => state.context.files_tree_compact)


  const [value, setValue] = React.useState<GhPPTreeType>('compact');

  const [expanded, setExpanded] = React.useState(false)

  //const setActiveDoc = (path:string)=>console.log("setActiveDoc", path)
  const setActiveDoc = (path:string)=>svc.send({
    type:"EVENTS.UI.SET_ACTIVE_DOC",
    path: path
    
  })
  {/* <RecursiveItem data={sampleData} depth={0}></RecursiveItem> */ }
  return (
    <Box
      sx={{
        p: 1,
        
      }}
    >



      <MdFileWrapPaper variant="outlined" className="article-paper-outlined" sx={{
        mb: 1,
        height:"auto"
        
      }}>
        
      </MdFileWrapPaper>

      <MdFileWrapPaper variant="outlined" className="article-paper-outlined" sx={{
       
        height:"100%"
        
      }}>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          disablePadding
        subheader={
          <ListSubheader component="div" id="nested-list-subheader" >
 <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",

          }}
        >
          <GhTreeExandRadio value={expanded} setValue={setExpanded} />

          <GhTreeTypeSelectRadio value={value} setValue={setValue} />
        </Box>
          <Divider />
          </ListSubheader>
        }
        >
          {/* <RecursiveListItem data={sampleData} depth={0}></RecursiveListItem> */}
          <RecursivePPTreeList 
            data={value === 'compact' ? (files_tree_compact.children || []) : (files_tree.children || [])} 
            depth={0} 
            expanded={expanded} 
            setActiveDoc={setActiveDoc}
          //  secActiveDoc={secActiveDoc}
          />
           

        </List>
      </MdFileWrapPaper>
    </Box>



  );
}



export { TreeNodeSidebarDisplay }