

import { useState } from 'react';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';

import { LeftSidebarTopCOntrols } from './LeftSidebarTopControls';

const SettingsBarCollapsibleWrapper = (props: {
    children?: React.ReactNode
}) => {
    const { children } = props
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (


        <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="settings-list-subheader"
            disablePadding
            subheader={
                <ListSubheader component="div" id="settings-list-subheader" sx={{
                    p: 0
                }}>

                    <LeftSidebarTopCOntrols />


                </ListSubheader>
            }
        >




        </List>




    )
}


export { SettingsBarCollapsibleWrapper }