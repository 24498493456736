import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
 
type FlatTreeRadioProps = {
  value:'flat' | 'tree',
  setValue: (value:'flat' | 'tree',)=>void
}

const  FlatTreeRadio = (props:FlatTreeRadioProps) =>{
  const {value, setValue} = props 

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value  as 'flat' | 'tree'
   // console.log("[GhTreeExandRadio] handleChange ", value)
    setValue( value);
  };




  return (
    <FormControl>
      <FormLabel id="flat-tree-radio-buttons-group">List View</FormLabel>
      <RadioGroup
      row
        aria-labelledby="flat-tree-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value={'tree'} control={<Radio />} label="Tree" />
        <FormControlLabel value={'flat'} control={<Radio />} label="Flat" />
      </RadioGroup>
    </FormControl>
  );
}



export {FlatTreeRadio}