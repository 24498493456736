import { useState, useEffect } from "react"
import { TreeNodeSidebarDisplay} from '../../layout/TreeNodeSidebarDisplay'

import { GhRepoParserMachineContext } from "../ghrepomachine";
import { ListView } from "./ListViewMain";
import { DetailsView } from "./DetailsView";
import { useSelector } from '@xstate/react';
import { GhRepoTreeContentItem } from "../../../../types/app.types";

const RepositoryViewer = ()=>{

    const svc = GhRepoParserMachineContext.useActorRef()

    // //   const svc = GhRepoParserMachineContext.useActorRef()
    // //   const current_doc = useSelector(svc, (state) =>  state.context.current_doc)
    const {
        list_view,
        details_view,
        selected_document,
        repo_details,
    } = useSelector(svc, (state) => ({
        list_view: state.matches('repo_viewer.list'),
        details_view: state.matches('repo_viewer.details'),
        selected_document: state.context.selected_document,
        repo_details: state.context.repo_details
    }))


    const [viewMode, setViewMode] = useState<'flat' | 'tree'>('tree')
  
    return (
        list_view? <ListView  viewMode={viewMode} setViewMode={setViewMode} />:
        details_view && selected_document? <DetailsView  />:
        <>Unknown state</>
      
    )
}



export {RepositoryViewer}