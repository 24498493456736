import { useState, useEffect } from "react"
import { Box } from '@mui/material'
import { useSelector } from "@xstate/react";

import { GhRepoParserMachineContext } from "../ghrepomachine";
import { DetailsViewDocumentTitle } from './DetailsViewDocumentTitle'
import { MarkdownRender } from "../../../../components/markdown/MarkdownRender";


const DetailsView = () => {


  const svc = GhRepoParserMachineContext.useActorRef()

  const {
    selected_document,
    repo_details
  } = useSelector(svc, (state) => ({
    selected_document: state.context.selected_document,
    repo_details: state.context.repo_details
  }))




  const [mdText, setMdText] = useState('');
  // const [docUrl, setDocUrl] = useState<string>(`https://raw.githubusercontent.com/${repo_details.full_name}/${repo_details.default_branch}/${selected_document.path}`)





  const [loading, setLoading] = useState<boolean>(false)


  useEffect(() => {

    if (undefined === selected_document) return

    //TODO: fetch doc content with an API
    const download_url = `https://raw.githubusercontent.com/${repo_details.full_name}/${repo_details.default_branch}/${selected_document.path}`
    console.log("[DetailsView]document changed", download_url)

    setMdText("")
    setLoading(true)
    fetch(download_url, { cache: 'force-cache' })
      .then((response) => {
        if (response.ok) return response.text();
        else return Promise.reject("Didn't fetch text correctly");
      })
      .then((text) => {
        setMdText(text)
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false))

    //  setDocUrl(download_url)
  }, [selected_document])



  const setListFn = () => svc.send({
    type: "EVENTS.UI.SET_ACTIVE_DOC",
    path: undefined
  })


  return (

    <Box>
      {/* <Box>
        <IconButton edge="end" aria-label="document"
          onClick={setListFn}
        >   <CloseIcon />

        </IconButton>
      </Box> */}
      <DetailsViewDocumentTitle doc={selected_document!} closeFn={setListFn} />
      <MarkdownRender text={mdText}/>
    </Box>
  )
}



export { DetailsView }