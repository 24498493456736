import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { GhRepoParserMachineContext } from '../ghrepomachine';
import { useSelector } from '@xstate/react';

import ArticleIcon from '@mui/icons-material/Article';
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const DetailsRightSidebarTabs = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    const svc = GhRepoParserMachineContext.useActorRef()

    const {

        recent_visited,
        selected_document
    } = useSelector(svc, (state) => ({

        recent_visited: state.context.recent_visited,
        selected_document: state.context.selected_document,
    }))


    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Item One" {...a11yProps(0)} />
                    <Tab label="Item Two" {...a11yProps(1)} />
                    <Tab label="Item Three" {...a11yProps(2)} />
                </Tabs>
            </Box>

            <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="div"
                aria-labelledby="settings-list-subheader"
                disablePadding
    
            >
                <CustomTabPanel value={value} index={0}>


                    {recent_visited.map((item) => {

                        return (
                            <ListItemButton
                                selected={item.sha === selected_document?.sha}
                                key={item.sha}
                                onClick={() => svc.send({ type: "EVENTS.UI.SET_ACTIVE_DOC", path: item.path })}
                                divider
                            >
                                <ListItemIcon>
                                    {/* <Avatar sx={{ bgcolor: deepPurple[500],  width: 24, height: 24, fontSize:14  }}>1</Avatar> */}
                                    <ArticleIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={item.path.split('/').pop()} secondary={item.path} />
                            </ListItemButton>)
                    })
                    }




                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    Item Two
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    Item Three
                </CustomTabPanel>
            </List>
        </Box>
    );
}


export { DetailsRightSidebarTabs }