import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { type GhPPTreeType } from '../../../types/app.types';
type GhTreeTypeSelectRadioProps = {
  value:GhPPTreeType,
  setValue: (value:GhPPTreeType)=>void
}

const  GhTreeTypeSelectRadio = (props:GhTreeTypeSelectRadioProps) =>{
  const {value, setValue} = props 

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as GhPPTreeType);
  };




  return (
    <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">Tree</FormLabel>
      <RadioGroup
      row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="original" control={<Radio />} label="Full" />
        <FormControlLabel value="compact" control={<Radio />} label="Compact" />
      </RadioGroup>
    </FormControl>
  );
}



export {GhTreeTypeSelectRadio}