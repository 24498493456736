import { useState } from 'react';

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { styled, useTheme } from '@mui/material/styles';


import Toolbar from '@mui/material/Toolbar';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import {
    useNavigate
  } from "react-router-dom"; 

  
import { useAppContext } from '../../../service/context/app/useAppContext';
// import { GhRepoParserMachineContext } from "../github/ghrepomachine";
// import { useSelector } from '@xstate/react';
 


interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }
  
type GhViewAppBarProps = {
    open:boolean,
    setOpen:(open:boolean)=>void,
    drawerWidth: number
}


const GhViewAppBar = (props:GhViewAppBarProps) => {

    const {open, setOpen, drawerWidth} = props;
    const { mode, setMode  } = useAppContext()
    const navigate = useNavigate()

    
    // const svc = GhRepoParserMachineContext.useActorRef()
    // const token = useSelector(svc, (state) => state.context.access_token)

    // const [loggedIn, _] = useState<boolean>(Boolean(token))



    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
      })<AppBarProps>(({ theme, open }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginRight: drawerWidth,
        }),
      }));



    return (
        <AppBar position="absolute" open={open} sx={{ flexGrow: 0 }} elevation={0}>
            <Toolbar>


                <TextSnippetIcon sx={{ display: 'flex', mr: 1 }} />
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    //  href="/"
                    onClick={() => navigate("/")}
                    sx={{
                        mr: 2,
                        display: 'flex',
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.15rem',
                        color: 'inherit',
                        textDecoration: 'none',
                        cursor: "pointer",
                        alignItems: "baseline"
                    }}
                >
                    MD<sub
                        style={{
                            left: -2,
                            bottom: 0,
                            fontFamily: 'Playball',
                            position: "relative",
                            fontSize: "19px",
                            fontWeight: "normal"
                        }}
                    >ok</sub>
                </Typography>
                <div style={{
                    flexGrow: 1
                }} ></div>



                {
                    mode === 'dark' ?


                        <IconButton
                            color="inherit"
                            aria-label="light mode"
                            edge="end"
                            onClick={() => setMode('light')}

                        >
                            <LightModeIcon />
                        </IconButton>
                        :

                        <IconButton
                            color="inherit"
                            aria-label="dark mode"
                            edge="end"
                            onClick={() => setMode('dark')}

                        >
                            <DarkModeIcon />
                        </IconButton>
                }


                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    href='/'
                   // sx={{ ...(open && { display: 'none' }) }}
                >
                    <HomeIcon />
                </IconButton>

                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={()=>setOpen(true)}
                    sx={{ ...(open && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}
export { GhViewAppBar }