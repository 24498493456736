import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import IconButton from '@mui/material/IconButton'
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ViewListIcon from '@mui/icons-material/ViewList';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

import { FullCompactToggle } from './FullCompactToggle'

import { GhRepoParserMachineContext } from '../ghrepomachine';
import { useSelector } from '@xstate/react';


const LeftSidebarTopCOntrols = () => {

    const svc = GhRepoParserMachineContext.useActorRef()
    const catalog_tree_display_settings = useSelector(svc, (state) => state.context.catalog_tree_display_settings)



    //   useEffect(()=>{
    //         console.log(`[LeftSidebarTopCOntrols] catalog_tree_display_settings updated`, catalog_tree_display_settings)
    //       },[catalog_tree_display_settings])

    const toggleFlatView = (mode: typeof catalog_tree_display_settings.viewMode) => {

        svc.send({
            type: "EVENTS.UI.CATALOG_TREE.UPDATE_DISPLAY_SETTINGS_VALUE",
            key: "viewMode",
            value: mode === 'tree' ? 'flat' : 'tree'
        })
        //  console.log("[LeftSidebarTopCOntrols] handleExpanded", expanded, value)
    }

    const toggleCompactTree = (value: boolean) => {

        svc.send({
            type: "EVENTS.UI.CATALOG_TREE.UPDATE_DISPLAY_SETTINGS_VALUE",
            key: "compact_tree",
            value: value
        })
        //  console.log("[LeftSidebarTopCOntrols] handleExpanded", expanded, value)
    }
    const handleExpanded = (value: boolean) => {

        // const {expanded} = catalog_tree_display_settings
        svc.send({
            type: "EVENTS.UI.CATALOG_TREE.UPDATE_DISPLAY_SETTINGS_VALUE",
            key: "expanded",
            value: value
        })
        //  console.log("[LeftSidebarTopCOntrols] handleExpanded", expanded, value)
    }

    // const [expanded, setExpanded] =  useState(false)  


    const [open, setOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [sortingOpen, setSortingOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const [alignment, setAlignment] = useState('tree');


    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    return (
        <div

        >
            <Box
                sx={{
                    m: 0.5,
                    p: 0.5,
                    display: 'flex',
                    //alignItems: 'center',
                    //width: 'fit-content',
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    bgcolor: 'background.paper',
                    color: 'text.secondary',
                    '& svg': {
                        m: 0.5,
                    },
                    '& hr': {
                        mx: 0.5,
                    },
                }}
            >




                {/* <Divider orientation="vertical" flexItem /> */}
                <IconButton onClick={() => toggleFlatView(catalog_tree_display_settings.viewMode)} size='small' sx={{ p: 0 }}>
                    <AccountTreeIcon fontSize="small" color={catalog_tree_display_settings.viewMode === 'tree' ? 'primary' : 'inherit'} />
                </IconButton>
                <Divider orientation="vertical" flexItem />

                {
                    catalog_tree_display_settings.viewMode === 'tree' ?
                        <>
                            <IconButton onClick={() => handleExpanded(false)} size='small' sx={{ p: 0 }}>
                                <UnfoldLessIcon fontSize="small" color={!catalog_tree_display_settings.expanded ? 'primary' : 'inherit'} />
                            </IconButton>
                            <Divider orientation="vertical" flexItem />


                            <IconButton onClick={() => handleExpanded(true)} size='small' sx={{ p: 0 }}>
                                <UnfoldMoreIcon fontSize="small" color={catalog_tree_display_settings.expanded ? 'primary' : 'inherit'} />
                            </IconButton>
                            <Divider orientation="vertical" flexItem />
                        </>
                        : null
                }


                <div style={{ flexGrow: 1 }}></div>
                <Divider orientation="vertical" flexItem />
                <IconButton onClick={() => setSortingOpen(!sortingOpen)} size='small' sx={{ p: 0 }}>
                    <SortByAlphaIcon fontSize="small" color={sortingOpen ? 'primary' : 'inherit'} />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton onClick={() => setSearchOpen(!searchOpen)} size='small' sx={{ p: 0 }}>
                    <SearchIcon fontSize="small" color={searchOpen ? 'primary' : 'inherit'} />
                </IconButton>

                <Divider orientation="vertical" flexItem />
                <IconButton onClick={handleClick} size='small' sx={{ p: 0 }}>
                    <SettingsIcon fontSize="small" color={open ? 'primary' : 'inherit'} />
                </IconButton>
            </Box>


            <Collapse in={open} timeout="auto" unmountOnExit>

                <Box
                    sx={{

                        p: 1
                    }}
                >

                    <FullCompactToggle
                        value={catalog_tree_display_settings.compact_tree}
                        setValue={toggleCompactTree}

                    />
                </Box>

            </Collapse>
            <Collapse in={searchOpen} timeout="auto" unmountOnExit>
                <List component="div"


                >

                    <Box
                        sx={{

                            p: 1
                        }}
                    >
                        <TextField fullWidth label="fullWidth" />
                    </Box>


                </List>

            </Collapse>


            <Collapse in={sortingOpen} timeout="auto" unmountOnExit>

                <Box
                    sx={{

                        p: 1
                    }}
                >
 
                </Box>

            </Collapse>

        </div>
    );
}



export { LeftSidebarTopCOntrols }