import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { GhRepoParserMachineContext } from '../ghrepomachine';
import { useSelector } from '@xstate/react';

import ArticleIcon from '@mui/icons-material/Article';
export default function FlatMdResultsList() {
  const [open, setOpen] = React.useState(true);
  const svc = GhRepoParserMachineContext.useActorRef()

  const {

    mdfiles,
    selected_document
} = useSelector(svc, (state) => ({

    mdfiles: state.context.mdfiles,
    selected_document: state.context.selected_document,
}))



  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {mdfiles.length} documents found
        </ListSubheader>
      }
    >

      {mdfiles.map((item)=>{

        return (    
        <ListItemButton 
          selected={item.sha === selected_document?.sha}
          key={crypto.randomUUID()}
          onClick={()=>svc.send({type:"EVENTS.UI.SET_ACTIVE_DOC", path: item.path})}
          divider
        >
          <ListItemIcon>
          {/* <Avatar sx={{ bgcolor: deepPurple[500],  width: 24, height: 24, fontSize:14  }}>1</Avatar> */}
          <ArticleIcon fontSize="small"    />
          </ListItemIcon>
          <ListItemText primary={item.path.split('/').pop()} secondary={item.path} />
        </ListItemButton>)
      })}
  

   
    
    </List>
  );
}