import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { red, green, blue, blueGrey } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GhRepoParserMachineContext } from "../ghrepomachine";
import { useSelector } from '@xstate/react';

const CatalogTreeRepoTitle = () => {


  const svc = GhRepoParserMachineContext.useActorRef()
 
  const repo_details = useSelector(svc, (state) => state.context.repo_details)

  const { full_name, pushed_at } = repo_details

  return (
    <CardHeader
    sx={{
        width:"100%",
        py: 0
    }}
    avatar={
        <Avatar sx={{ bgcolor: blue[500] }}  >
        <AssignmentIcon />
      </Avatar>
    }
    action={
      <IconButton aria-label="settings">
        <MoreVertIcon />
      </IconButton>
    }
    title={full_name}
    subheader={`${pushed_at}`}

    titleTypographyProps={{
        variant:"button"
    }}
    subheaderTypographyProps={{
        variant:"body2"
    }}
  />
  );
}

export {CatalogTreeRepoTitle}
