import { useEffect, useState, useInsertionEffect } from "react"
import { useLocation , useNavigate} from "react-router-dom"

import { GhRepoParserMachineContext } from "../../widgets/article/github/ghrepomachine";
import { useAppContext } from "../../service/context/app/useAppContext";
import { useSelector } from "@xstate/react";

// https://github.com/login/oauth/authorize?client_id=7ba8e5b631683f845300&redirect_uri=http://localhost:3000/auth/callback/github&state=false
 



    
  const postApiRequest = ( endpoint: string, payload: object) => new Promise(async (resolve, reject) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
  
  
    const response = await fetch(endpoint, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload)
    });
  
  
    console.log(`[postApiRequest]: ${endpoint}: ${response.ok}, ${response.status}`)
  
    if (!response.ok) console.log("[HANDLEME!]response.ok is not true")
    if (!(response.status === 200)) console.log("[HANDLEME!]response.status is not 200")
  
  
    const data = await response.json();
  
    if (!response.ok) reject(data)
    else resolve(data)
  
  
  })
  
  
type GithubAccessTokenResponse = {
    access_token:string, 
    scope: string, 
    token_type: "bearer" | "device"
}

const GithubAuthCallback = ()=>{
    const location = useLocation()
    const navigate = useNavigate()
    
    const svc = GhRepoParserMachineContext.useActorRef()

 
    const config = useSelector(svc, (state) => state.context.config)
    const _access_token = useSelector(svc, (state) => state.context.access_token)

    useEffect(()=>{

        if(undefined !== _access_token) return //dev reload fix
        if (config.ghExchangeTokenUrl === undefined) return


        console.log("GithubAuthCallback called",location.search)
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code')
        const state = urlParams.get('state')

        if (null === code || null === state) return

        const githubTokenExchane = async (code:string, state:string)=>{

        
            console.log("GithubAuthCallback] started",config)
            //const endpoint = await  loadApiConfig() as string
           
            const data = await postApiRequest(config.ghExchangeTokenUrl, { code, state}) as GithubAccessTokenResponse
            const {access_token, scope, token_type} = data as GithubAccessTokenResponse
            svc.send({
                type:"EVENTS.AUTH.ACCESS_TOKEN.SET",
                access_token: access_token
            })

            console.log("GithubAuthCallback] finisd",data)
        }

        githubTokenExchane(code,state )
        .then(()=>navigate('/github/repos/hello'))
        .catch((e)=>console.log(e))

        
    },[config])

    return (
        <></>
    )
}


export { GithubAuthCallback }