import { useState, useEffect, useMemo } from "react";

import {  Link } from "react-router-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from "@mui/material";
import { type PaletteMode } from "@mui/material";

import { GithubAuthCallback } from "./auth/github/AuthCallback";

import {  PersistentDrawerRightLayout, GithubView} from "./widgets/article";

import {useAppContext} from './service/context/app/useAppContext'

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        // palette values for light mode
        // primary: amber,
        // divider: amber[200],
        // text: {
        //   primary: grey[900],
        //   secondary: grey[800],
        // },
      }
      : {
        // palette values for dark mode
        // primary: deepOrange,
        // divider: deepOrange[700],
        // background: {
        //   default: deepOrange[900],
        //   paper: deepOrange[900],
        // },
        // text: {
        //   primary: '#fff',
        //   secondary: grey[500],
        // },
      }),
  },
});




function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page of the app</Link>
      </p>
    </div>
  );
}



const routes = createBrowserRouter([

  {
    path: "/",
    element: <PersistentDrawerRightLayout />,
    children: [
      { index: true, element: <GithubView /> },

      {
        path: "/github/auth/callback",
        element: <GithubAuthCallback />
      },
      { path: "/github/repos/:repoId", element: <GithubView /> },

    ]
  },


]);

function App() {

  const {mode}  = useAppContext()

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <Box
      sx={{
        colorScheme: mode
      }}
    >
      <ThemeProvider theme={theme}>
          <CssBaseline />
          <RouterProvider router={routes} />
      </ThemeProvider>
    </Box>
  );
}

export default App;
