import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import GitHubIcon from '@mui/icons-material/GitHub';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'; 
import { GhRepoParserMachineContext } from "../github/ghrepomachine";
import { useSelector } from '@xstate/react';
 
import {DetailsRightSidebarTabs} from '../github/catalogview/DetailsRightSidebarTabs'

import VersionInfo from '../../../components/common/sidebar/VersionInfo';

const githubLogin = (client_id:string, redirect_uri:string)=>{
    console.log("githubLogin called")
    const _state = crypto.randomUUID()
    const url = `https://github.com/login/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&state=${_state}`
    window.location.href = url
  }

 

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));


  type GhViewDrawerProps = {
    open:boolean,
    setOpen:(open:boolean)=>void,
    drawerWidth: number
}



const GhViewDrawer = (props: GhViewDrawerProps)=>{
    const {open, setOpen, drawerWidth} = props;
    const theme = useTheme();
   
    const svc = GhRepoParserMachineContext.useActorRef()
    const config = useSelector(svc, (state) => state.context.config)
    const token = useSelector(svc, (state) => state.context.access_token)
  
   
    
    const handleDrawerClose = () => {
        setOpen(false);
      };
    

    return(
        <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <div style={{  flexGrow: 1 }} ></div>
             
           

          <IconButton onClick={()=>{
                //console.log("[GhViewDrawer] githubLogin clicked", config)
                githubLogin(config.ghAppClientId, config.ghAppRedirectUri)
          }}>
            <GitHubIcon 
              color={token?'primary':'inherit'}
            />
          </IconButton>


          
        </DrawerHeader>
        <Divider />
       
          <DetailsRightSidebarTabs />
  
        <Box
          component={"div"}
          sx={{
            flexGrow: 1
          }}
        ></Box>
        <Divider />
        <VersionInfo />



      </Drawer>
    )
}



export { GhViewDrawer }