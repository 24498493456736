import { createMachine, assign, sendParent, send, ActorRefFrom } from "xstate";
import { createActorContext } from '@xstate/react';
import { AppConfig, GhRepoDetails, GhRepoFolderContents, GhRepoItem, GhRepoBranch, GhRepoTree, GhRepoTreeContentItem, TreeNode } from "../../../types/app.types";



const USE_FAKE_DATA = false

type MachineContext = {
  username: string | undefined
  repo: string | undefined,
  repo_details: GhRepoDetails,
  repo_branches: GhRepoBranch[],
  repo_tree_content: GhRepoTreeContentItem[],
  selected_document: GhRepoTreeContentItem | undefined,
  access_token: string | undefined,
  mdfiles: GhRepoTreeContentItem[],
  current_doc: GhRepoItem | undefined,
  config: AppConfig,
  // origin_tree: TreeObjPPItem | undefined,
  // optimized_tree: TreeObjPPItem | undefined,
  files_tree: TreeNode,
  files_tree_compact: TreeNode,
  catalog_tree_display_settings: CatalogTreeDisplaySettings,
  recent_visited:  GhRepoTreeContentItem[],
  starred:  GhRepoTreeContentItem[],
  blacklisted:  GhRepoTreeContentItem[]
};


type MachineState =
  | { value: "preload_data"; context: MachineContext }
  | { value: "waiting_for_input"; context: MachineContext }
  | { value: "load_data"; context: MachineContext }
  | { value: "load_data.get_repo_details"; context: MachineContext }
  | { value: "load_data.get_tree"; context: MachineContext }
  | { value: "load_data.parse_tree"; context: MachineContext }
  | { value: "repo_viewer"; context: MachineContext }
  | { value: "repo_viewer.list"; context: MachineContext }
  | { value: "repo_viewer.details"; context: MachineContext }
  | { value: "set_active_doc"; context: MachineContext }
  | { value: "set_active_doc.fetch_doc"; context: MachineContext }
  | { value: "set_active_doc.done"; context: MachineContext }
  | { value: "error"; context: MachineContext }

type MachineEvent =
  | {
    type: 'EVENTS.REPO.SETUP',
    repo_link: string
  } | {
    type: 'EVENTS.REPO.CLEAR'
  } | {
    type: 'EVENTS.UI.SET_ACTIVE_DOC',
    path: string | undefined
  } | {
    type: 'EVENTS.AUTH.ACCESS_TOKEN.SET',
    access_token: string
  }| {
    type: 'EVENTS.UI.CATALOG_TREE.UPDATE_DISPLAY_SETTINGS_VALUE',
    key: keyof CatalogTreeDisplaySettings,
    value: CatalogTreeDisplaySettings[keyof CatalogTreeDisplaySettings]
  }








type CatalogTreeDisplaySettings = {
  viewMode: "tree" | "flat",
  expanded: boolean,
  compact_tree: boolean,
  orderBy: "name" | "size" | "path"
}

let catalog_tree_display_settings_defaults : CatalogTreeDisplaySettings = {
  viewMode:"tree",
  expanded:false,
  compact_tree:false,
  orderBy: "name" 
}


// const updateSettings = (key: keyof CatalogTreeDisplaySettings, value: CatalogTreeDisplaySettings[keyof CatalogTreeDisplaySettings]) => {
//   catalog_tree_view_settings_defaults = { ...catalog_tree_view_settings_defaults, [key]: value };
// };





const loadApiConfig = () => new Promise<AppConfig>(async (resolve, reject) => {
  const response = await fetch('/config.local.json');
  const config = await response.json();
  resolve(config)
});



const getRepoDetails = (_: MachineContext) => new Promise<GhRepoDetails>(async (resolve, reject) => {


  if (USE_FAKE_DATA) {

    const response = await fetch('/fake_data/repo_details.json');
    const _json = await response.json();
    resolve(_json)
    return
  }


  const { username, repo } = _
  const endpoint = `https://api.github.com/repos/${username}/${repo}`
  const data = await getApiRequest(endpoint, _.access_token) as GhRepoDetails;
  resolve(data)

})


const getRepoBranches = (_: MachineContext) => new Promise<GhRepoBranch[]>(async (resolve, reject) => {

  const { username, repo } = _
  const endpoint = `https://api.github.com/repos/${username}/${repo}/branches`
  const data = await getApiRequest(endpoint, _.access_token) as GhRepoBranch[];
  resolve(data)

})

// type TreeObjPPItem = {
//   name: string,
//   parent_path: string,
//   docs_count: number,
//   children: TreeObjPPItem[]
// }




const getRepoTreeRecursive = (_: MachineContext) => new Promise<GhRepoTree>(async (resolve, reject) => {

  if (USE_FAKE_DATA) {

    const response = await fetch('/fake_data/repo_tree.json');
    const _json = await response.json();
    resolve(_json)
    return
  }

  const headers = new Headers();
  headers.append("accept", "application/vnd.github+json");
  headers.append("X-GitHub-Api-Version", "2022-11-28");

  const { access_token, repo_details } = _
  const recursive = true

  if (undefined !== access_token) {
    const bearer = `Bearer ${access_token}`;
    headers.append("Authorization", bearer);
  }


  const { trees_url, default_branch } = repo_details

  const endpoint = `${trees_url.replace('{/sha}', '/' + default_branch)}`



  const response = await fetch(`${endpoint}?recursive=${recursive}`, {
    method: "GET",
    headers: headers,
    cache: "force-cache"
  });


  console.log(`[getRepoTreeRecursive]: ${endpoint}: ${response.ok}, ${response.status}`)

  if (!response.ok) reject("[HANDLEME!]response.ok is not true")
  if (!(response.status === 200)) reject("[HANDLEME!]response.status is not 200")


  const data = await response.json();

  resolve(data)



  // const {username, repo} = _
  // const endpoint  = `https://api.github.com/repos/${username}/${repo}/contents/`
  //   const data = await getApiRequest( endpoint, token);

  //  // console.log(`[scanRepoContents][${depth}] folder contents fetched`, data)




  //   resolve(data as GhRepoFolderContents)

})



function getFilePathWithoutFilename(fullPath: string): string {
  const regex = /(.*\/)[^\/]+$/;
  const match = regex.exec(fullPath);
  return match ? match[1] : "/";
}




function optimizeTreeNode(tree: TreeNode) {

  const cloned_tree = JSON.parse(JSON.stringify(tree));
  const nodes = [cloned_tree];


  let counter = 0
  let prev_node: TreeNode | undefined = undefined
  let currentNode: TreeNode | undefined = undefined
  let dest_node: TreeNode | undefined = undefined

  while ((currentNode = nodes.pop())) {
    let is_child = prev_node?.children && prev_node.children.indexOf(currentNode) > -1 ? 1 : 0
    let children_cnt = currentNode.children?.length || 0
    let docs = currentNode.children?.filter((x) => x.type === "blob").length || 0
    let replace_to = ""


    if (is_child && children_cnt === 1 && docs === 0) {
      counter += 1

    }
    else {

      //  prev_counter = counter + 0
      if (dest_node && is_child && counter > 0) {
        replace_to = dest_node.path
        console.log(`[optimizePPTree] moving content from  ${currentNode.path} to  ${replace_to}, docs: ${docs}, children:`, currentNode.children)
        dest_node.children = currentNode.children//[currentNode]
        
        // if(docs === 0) dest_node.children = currentNode.children //[currentNode]
        // else dest_node.children = [currentNode]

      }
      counter = 0
      // dest = ""
      dest_node = undefined
    }

    if (counter === 1) {
      //  dest = currentNode.parent_path
      dest_node = currentNode

    }

    //console.log( {is_child, children_cnt, docs, counter}, currentNode.parent_path, replace_to.length ? `=>${replace_to}`:`` ); // Process current node

    for (const child of currentNode.children || []) {
      nodes.push(child);
    }
    prev_node = currentNode
  }
  return cloned_tree
}


function buildTree(data: { path: string; type: string }[]): TreeNode {
  const root: TreeNode = { path: "", type: "tree", children: [] };
  for (const item of data) {
    const pathSegments = item.path.split("/");
    let node = root;
    for (let i = 0; i < pathSegments.length; i++) {
      const segment = pathSegments[i];
      const currentPath = pathSegments.slice(0, i + 1).join("/");

      if (!node.children) {
        node.children = [];
      }

      if (i === pathSegments.length - 1) {
        node.children.push({ path: item.path, type: item.type });
      } else if (!node.children.find((child) => child.path === currentPath)) {
        // Use `children?.find` to handle potentially undefined children
        node.children.push({ path: currentPath, type: "tree", children: undefined });
      }

      node = node.children.find((child) => child.path === currentPath)!; // Use optional chaining to handle potentially undefined children
    }
  }
  return root;



}// Define a dictionary to store parent-child relationships.

const parseTree = (_: MachineContext) => new Promise<any>(async (resolve, reject) => {

  // const { repo_tree_content } = _
  // const mdfiles = repo_tree_content.filter((item) => /(\w+)\.md$/i.test(item.path))

  let { mdfiles } = _

  mdfiles.sort((a,b)=>a.path.includes("/")?1:-1)
  const _tree = buildTree(mdfiles)
  const optimized = optimizeTreeNode(_tree)

  console.log("[parseTree] mdfiles", mdfiles)

  resolve({
  //  mdfiles: mdfiles,
    original: _tree,
    compact: optimized
  })

})




const getApiRequest = (endpoint: string, token?: string) => new Promise(async (resolve, reject) => {


  const headers = new Headers();
  headers.append("accept", "application/json");

  if (undefined !== token) {
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
  }


  const response = await fetch(endpoint, {
    method: "GET",
    headers: headers
  });


  console.log(`[getApiRequest]: ${endpoint}: ${response.ok}, ${response.status}`)

  if (!response.ok) reject("[HANDLEME!]response.ok is not true")
  if (!(response.status === 200)) reject("[HANDLEME!]response.status is not 200")


  const data = await response.json();

  resolve(data)


})

const parseRepoLink = (repo_link: string) => {
  const addr = repo_link.trim()


  const groups = addr.match(/https\:\/\/github\.com\/([\w.-]+)\/([\w.-]+)/)
  if (groups?.length !== 3) return []
  if (groups[0] !== addr) return []

  return groups

}

const repoAddrValid = (context: MachineContext, event: MachineEvent) => {
  if (event.type !== "EVENTS.REPO.SETUP") return false

  const addr = event.repo_link.trim()
  const groups = parseRepoLink(addr)
  if (!groups.length) return false
  return true //context.canSearch && event.query && event.query.length > 0;
};


export const ghRepoParserMachine = createMachine<
  MachineContext,
  MachineEvent,
  MachineState
>({
  initial: "preload_data",
  id: "ghrepomachine",
  predictableActionArguments: true,
  context: {
    repo: undefined,
    username: undefined,
    repo_details: {} as GhRepoDetails,
    access_token: undefined,
    mdfiles: [], //mdlistsample as GhRepoFolderContents,
    current_doc: undefined,
    config: {} as AppConfig,
    repo_branches: [],
    repo_tree_content: [],
    files_tree: {} as TreeNode,
    files_tree_compact: {} as TreeNode,
    selected_document: {} as GhRepoTreeContentItem,
    catalog_tree_display_settings: catalog_tree_display_settings_defaults,
    recent_visited:[],
    starred:[],
    blacklisted:[]
  },

  on: {

    'EVENTS.UI.CATALOG_TREE.UPDATE_DISPLAY_SETTINGS_VALUE':{
      actions:[
        assign((_,e)=>({ catalog_tree_display_settings:  { ..._.catalog_tree_display_settings, [e.key]: e.value }}))
      ]
    },
    
    'EVENTS.AUTH.ACCESS_TOKEN.SET': {
      actions: [
        assign((_, e) => ({ access_token: e.access_token })),
        (_, e) => console.log("[ghrepomachine]new token setup!", _.access_token)
      ]
    },

    'EVENTS.UI.SET_ACTIVE_DOC': [{
      actions: [
        assign((_, e) => {
          const _file = _.mdfiles.find(x=>x.path==e.path)
          if (!_file) return {}
          
          const _el_index = _.recent_visited.indexOf(_file)
          let recent_visited = []


          if (_el_index > -1){
              recent_visited = [..._.recent_visited]
              // Remove the element at the given index
                const element = recent_visited.splice(_el_index, 1)[0];

                // Push the element to the beginning of the array
                recent_visited.unshift(element);
          }
          else {
            recent_visited = [ _file, ..._.recent_visited]
          }
          
         return {
            selected_document:  _file,
            recent_visited: recent_visited
          }
        }),
        (_,e)=>console.log(e.type, _.selected_document)
      ],
      target: "repo_viewer.details",
      cond:(_,e)=>e.path !== undefined
    },
    {
      actions: [
        assign((_, e) => ({selected_document:  undefined})),
        (_,e)=>console.log(e.type, _.selected_document)
      ],
      target: "repo_viewer.list",
      cond:(_,e)=>e.path === undefined
    }
  
  ],
    'EVENTS.REPO.SETUP': [{
      target: "load_data",
      cond: repoAddrValid,
      actions: [
        assign((_, e) => {

          const { repo_link } = e
          const groups = parseRepoLink(repo_link)
          const [origin, username, repo] = groups

          return {
            username: username,
            repo: repo
          }
        })
      ]

    }, {
      target: "error"
    }
    ]
  },
  states: {
    preload_data: {
      entry: (_, e) => console.log("ghrepomachine.preload_data entry", e),
      exit: (_, e) => console.log("ghrepomachine.preload_data exit", e),
      invoke: {
        src: loadApiConfig,
        onDone: {
          actions: [
            (_, e) => console.log("ghrepomachine.preload_data loadApiConfig.onDone", e),
            assign((_, e) => {
              return {
                config: e.data
              }
            })

          ],
          target: "waiting_for_input"
        }
      }
    },
    waiting_for_input:{
      entry: (_, e) => console.log("ghrepomachine.waiting_for_input entry", e),
      exit: (_, e) => console.log("ghrepomachine.waiting_for_input exit", e),

    },

    load_data: {
      entry: (_, e) => console.log("ghrepomachine.load_data entry", e),
      exit: (_, e) => console.log("ghrepomachine.load_data exit", e),
      initial: "get_repo_details",
      states: {
        get_repo_details: {
          entry: (_, e) => console.log("ghrepomachine.load_data.get_repo_details entry", e),
          exit: (_, e) => console.log("ghrepomachine.load_data.get_repo_details exit", e),
          invoke: {
            src: getRepoDetails,
            onDone: {
              actions: [
                (_, e) => console.log("ghrepomachine.load_data.get_repo_details.onDone", e.data),
                assign((_, e) => {
                  //order matters

                  return {
                    repo_details: e.data,


                  }
                })
              ],
              target: "get_tree"
            },
            onError: {
              actions: [
                (_, e) => console.log("ghrepomachine.load_data.get_repo_details.getRepoDetails onError", e),
              ],
            }
          }
        },
        get_tree: {
          entry: (_, e) => console.log("ghrepomachine.load_data.get_tree entry", e),
          exit: (_, e) => console.log("ghrepomachine.load_data.get_tree exit", e),
          invoke: {
            src: getRepoTreeRecursive,
            onDone: {
              actions: [
                //    (_, e) => console.log("ghrepomachine.check_link.get_tree.getRepoTreeRecursive onDone", e.data),
                assign((_, e) => ({ 
                  mdfiles: e.data.tree.filter((item:GhRepoTreeContentItem) => /(\w+)\.md$/i.test(item.path))
                  //const mdfiles = repo_tree_content.filter((item) => /(\w+)\.md$/i.test(item.path))
                }))
              ],
              target: "parse_tree"
            },
            onError: {
              actions: [
                (_, e) => console.log("ghrepomachine.load_data.get_tree.getRepoTreeRecursive onError", e),
              ],
            }
          }
        },
        parse_tree: {
          entry: (_, e) => console.log("ghrepomachine.load_data.parse_tree entry", e),
          exit: (_, e) => console.log("ghrepomachine.load_data.parse_tree exit", e),
          invoke: {
            src: parseTree,
            onDone: {
              actions: [
                (_, e) => console.log("ghrepomachine.load_data.parse_tree.parseTree onDone", e.data),
                assign((_, e) => ({
                  files_tree: e.data.original,
                  files_tree_compact: e.data.compact
                })),
                //  (_, e) => console.log("trees",  {original: _.files_tree, optimized: _.files_tree_compact}),
              ],
              target: "#ghrepomachine.repo_viewer"
            },
            onError: {
              actions: [
                (_, e) => console.log("ghrepomachine.load_data.parse_tree.parseTree onError", e),
              ],
            }
          }
        }
      }
    },

    repo_viewer : {
      entry: (_, e) => console.log("ghrepomachine.repo_viewer entry", e),
      exit: (_, e) => console.log("ghrepomachine.repo_viewer exit", e),
      initial:"list",
      states:{
        list:{},
        details:{}
      }
    },

    set_active_doc: {
      initial: "fetch_doc",
      states: {
        fetch_doc: {
          entry: [
            (_, e) => console.log("ghrepomachine.set_active_doc.fetch_doc entry", e),
            //   assign((_, e) => ({current_doc: ),
          ],
          exit: (_, e) => console.log("ghrepomachine.set_active_doc.fetch_doc exit", e),

          invoke: {
            src: (_, e) => new Promise(async (resolve, reject) => {
              console.log("hrepomachine.set_active_doc.fetch_doc invoke", e)
              if (e.type === 'EVENTS.UI.SET_ACTIVE_DOC') {
                const data = _.mdfiles.find(x => x.path === e.path)
                resolve(data)
              }
              else if (_.mdfiles.length > 0) {
                const readme = _.mdfiles.find(x => /readme\.md/i.test(x.path))


                resolve(readme || _.mdfiles[0])
              }
              else {
                reject(e)
              }


            }),
            onDone: {
              actions: assign((_, e) => {
                return {
                  current_doc: e.data
                }
              }),
              target: "#ghrepomachine.repo_viewer.details"
            }
          }
        },
        done: {
          entry: (_, e) => console.log("ghrepomachine.set_active_doc.done entry", e),
          exit: (_, e) => console.log("ghrepomachine.set_active_doc.done exit", e),
        }
      }
    },
    error: {
      on: {
        'EVENTS.REPO.CLEAR': {
          actions: assign(() => ({
            username: "",
            repo: ""
          })),
          target: "waiting_for_input"
        }
      },
      entry: (_, e) => console.log("ghrepomachine.error entry", e),
      exit: (_, e) => console.log("ghrepomachine.error exit", e),
    }
  }
}, {
  actions: {

  },

  // guards: {
  //   repoAddressValid: (context, event) => {
  //     return context.canSearch && event.query && event.query.length > 0;
  //   }
  // }
});


export const GhRepoParserMachineContext = createActorContext(ghRepoParserMachine, { devTools: true });
export type GhRepoParserMachineType = ActorRefFrom<typeof ghRepoParserMachine>