import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import "github-markdown-css/github-markdown.css"
//import "github-markdown-css/github-markdown-dark.css"
import reportWebVitals from './reportWebVitals';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

//import "./css/dist/dark_colorblind.css"
//import "./css/dist/dark_tritanopia.css"
//import "./css/dist/dark.css"
//import "./css/dist/dark_dimmed.css"

//import "./css/dist/dark_high_contrast.css"
// import "highlight.js/styles/github.css";
//import "highlight.js/styles/dark.css";
//import "highlight.js/styles/tokyo-night-dark.css";


//import "./css/dist/light_colorblind.css"
// import "./css/dist/dark_high_contrast.css"
//  import "./css/dist/light_tritanopia.css"
//  import "./css/dist/light.css"

 
//
// // import "highlight.js/styles/github.css"
// // import * as Sentry from "@sentry/react";
// // import { BrowserTracing } from "@sentry/tracing";

//import "highlight.js/styles/base16/github.css";
import "highlight.js/styles/tokyo-night-dark.css";
//import "highlight.js/styles/vs.css";
import "./css/custom/light.css"
import "./css/custom/dark.css"

import { GhRepoParserMachineContext } from './widgets/article/github/ghrepomachine';
import App from './App';


import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AppContextProvider } from './service/context/app/AppContext';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
// Sentry.init({
//   dsn: "https://XXXXXXX@XXXXXXX.ingest.sentry.io/XXXXXX",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   //tracesSampleRate: 1.0,
// });




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);






root.render(
  <React.StrictMode>
    {/* <ThemeConfig> */}


    {/* <GlobalStyles /> */}
    {/* <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider> */}
    {/* </ThemeConfig> */}

    <GhRepoParserMachineContext.Provider>
      <AppContextProvider>
          <App />
      </AppContextProvider>
    </GhRepoParserMachineContext.Provider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
