import { useState, useEffect } from "react"
import { TreeNodeSidebarDisplay } from '../../layout/TreeNodeSidebarDisplay'

import { GhRepoParserMachineContext } from "../ghrepomachine";
import { useSelector } from '@xstate/react';
import { Box } from "@mui/material";

import FlatList from './FlatList'
import { FlatTreeRadio } from "./FlatTreeSwtich";
import { GhRepoTreeContentItem } from "../../../../types/app.types";

const ListView = ({viewMode, setViewMode}:{
    viewMode:"flat" | "tree",
    setViewMode:(value:"flat" | "tree")=>void,
 
}) => {
 

    return (
        <Box>
            <Box>
                <FlatTreeRadio value={viewMode} setValue={setViewMode} />
            </Box>
            <Box>
                {viewMode === 'flat' ?
                    <FlatList />
                    :
                    <TreeNodeSidebarDisplay />
                }
            </Box>
        </Box>
    )
}



export { ListView }