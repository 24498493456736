import * as React from 'react';
import { styled  } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';

import Divider from '@mui/material/Divider';


import { LeftSidebarCatalogMain } from '../catalogview/Main';
import { CatalogTreeRepoTitle } from '../catalogview/CatalogTreeRepoTitle';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const  DetailsPersistentDrawerLeft = (props:{
    drawerWidth:number
})=> {

  const {drawerWidth}  = props
  const [open, setOpen] = React.useState(true);



  return (
    <Drawer
    sx={{
      width: drawerWidth,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: drawerWidth,
      },
    }}
    variant="permanent"
    anchor="left"
    open={open}
  >
    <DrawerHeader
        sx={{ p: 0 }}
    >
      {/* <IconButton onClick={handleDrawerClose}>
        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton> */}

      <CatalogTreeRepoTitle />
    </DrawerHeader>
    <Divider />
    <LeftSidebarCatalogMain  />
  </Drawer>
  );
}



export {DetailsPersistentDrawerLeft}