import * as React from 'react';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ViewListIcon from '@mui/icons-material/ViewList';

import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const FullCompactToggle = (props:{value:boolean, setValue:(value:boolean)=>void}) => {

const {value, setValue}= props


  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue !== null) {
      setValue(newValue === 'compact');
    }
  };

 

  return (
    <Stack direction="row" spacing={4}>
      <ToggleButtonGroup
        value={value?'compact':"full"}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
      >
        <ToggleButton value="full" aria-label="full">
          Full
        </ToggleButton>
        <ToggleButton value="compact" aria-label="compact">
          Compact
        </ToggleButton>

      </ToggleButtonGroup>


    </Stack>
  );
}


export {FullCompactToggle}
