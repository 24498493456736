import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//import Divider from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Collapse from '@mui/material/Collapse';
import { Typography, Box } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArticleIcon from '@mui/icons-material/Article';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { GhRepoParserMachineContext } from '../ghrepomachine';
import { useSelector } from '@xstate/react';

import { type GhPPTreeType, TreeNode } from '../../../../types/app.types';
 
//https://stackoverflow.com/a/2117523/592737
function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  ) as string;
}



const RecursiveExpandableListItem = ({ children, depth, primary, secondary, expanded }: { children: React.ReactNode, depth: number, primary: string, secondary: string, expanded: boolean }) => {
  const [open, setOpen] = React.useState(expanded);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>

      <ListItemButton onClick={handleClick} divider={false} sx={{
        p:0,
        pl: 2 * depth,
        

      }}>
        <ListItemIcon
          sx={{
            minWidth:0,
            pr:1
          }}
        >
          {/* <FolderOpenIcon fontSize="small" /> */}
          {open ?   <ExpandMore  fontSize="small" /> : <KeyboardArrowRightIcon   fontSize="small" />}
        </ListItemIcon>
        <ListItemText
          primary={primary}
         // secondary={secondary}
          sx={{ whiteSpace: "nowrap", maxWidth: "100%" }}
          primaryTypographyProps={{
            variant:"body2"
          }}
        />
      
      </ListItemButton>


      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div"
          disablePadding

        >
          {/* <ListSubheader component="div">
            Inner {name} Items
          </ListSubheader> */}
          {children}


        </List>
      </Collapse>
    </>
  )
}







const RecursivePPTreeList = (props: {
  data: TreeNode[],
  depth?: number,
  expanded: boolean,
  setActiveDoc: (path: string) => void
}) => {

  const { data, expanded, depth = 0, setActiveDoc } = props


  //  data.sort((a,b)=>a.type === 'blob'?-1:1)
  return (
    <>
      {data.map(item => (
        <React.Fragment
          key={uuidv4()}
        >

          {item.children?.length ?
            <RecursiveExpandableListItem

              primary={item.path.split('/').pop() || "empty"}
              secondary={item.path}
              depth={depth}
              expanded={expanded}
            >
              <RecursivePPTreeList
                depth={depth + 1}
                data={item.children}
                expanded={expanded}
                setActiveDoc={setActiveDoc}
              />
            </RecursiveExpandableListItem> :

            <ListItemButton
              
              onClick={() => setActiveDoc(item.path)}
              sx={{
                p:0,
                pl: 2 * depth,
               
              }}
            
           //   divider={true}
            >
              <ListItemIcon
                     sx={{
                      minWidth:0,
                      pr:1
                    }}
              >
                {/* <KeyboardArrowRightIcon fontSize="small" /> */}
                {/* <ArticleIcon  fontSize="small" /> */}
                
              </ListItemIcon>
              <ListItemText 
              primary={item.path.split('/').pop() || "empty"}
             //  secondary={item.path} 
               sx={{ 
                whiteSpace: "nowrap",
                maxWidth: "100%",
                cursor:"pointer",
              
              
              }}
               
               primaryTypographyProps={{
                variant:"body2",
                fontSize:"small",
                sx:{
                  ':hover': {
                    textDecoration:"underline"
                  }
                }
              }}
               />
            </ListItemButton>



          }

        </React.Fragment>

      ))}

    </>
  )
};


const CatalogTreeView = () => {
  //const [open, setOpen] = React.useState(true);

  const svc = GhRepoParserMachineContext.useActorRef()
  const files_tree = useSelector(svc, (state) => state.context.files_tree)
  const files_tree_compact = useSelector(svc, (state) => state.context.files_tree_compact)
  const catalog_tree_display_settings = useSelector(svc, (state) => state.context.catalog_tree_display_settings)

  const [value, setValue] = React.useState<GhPPTreeType>('compact');


  //const setActiveDoc = (path:string)=>console.log("setActiveDoc", path)
  const setActiveDoc = (path: string) => svc.send({
    type: "EVENTS.UI.SET_ACTIVE_DOC",
    path: path

  })
  // React.useEffect(()=>{
  //   console.log(`[CatalogTreeView] files_tree updated`, files_tree)
  // },[files_tree])


  React.useEffect(()=>{
    console.log(`[CatalogTreeView] catalog_tree_display_settings updated`, catalog_tree_display_settings)
  },[catalog_tree_display_settings])


  {/* <RecursiveItem data={sampleData} depth={0}></RecursiveItem> */ }
  return (

       
      <RecursivePPTreeList
        data={catalog_tree_display_settings.compact_tree ? (files_tree_compact.children || []) : (files_tree.children || [])}
        depth={0}
        expanded={catalog_tree_display_settings.expanded}
        setActiveDoc={setActiveDoc}
      />


 


  );
}



export { CatalogTreeView }