import { useState, useEffect, useRef, useLayoutEffect, useInsertionEffect } from "react";
import { Box, Container, Paper } from "@mui/material";


import { GhRepoParserMachineContext } from "./ghrepomachine";
import { useSelector } from '@xstate/react';

import { RepositoryViewer } from './repoviewer/RepositoryViewer'
import { WaitForInputView } from './WaitingForInput'
import { RepoSearchInput } from "./RepoSearchInput";

const GithubView = (props: {
  //mode: PaletteMode,
  //docUrl: string
}) => {



  const svc = GhRepoParserMachineContext.useActorRef()

  // //   const svc = GhRepoParserMachineContext.useActorRef()
  // //   const current_doc = useSelector(svc, (state) =>  state.context.current_doc)
  const {

    repo: _repo,
    waiting_for_input,
    load_data,
    repo_viewer,
    is_error

  } = useSelector(svc, (state) => ({

    repo: state.context.repo,

    waiting_for_input: state.matches('waiting_for_input'),
    is_error: state.matches('error'),
    load_data: state.matches('load_data'),
    repo_viewer: state.matches('repo_viewer'),

  }))





  return (
    <Box
      sx={{
        textAlign: "left",
        pt: 1,
        pb: 2,
        height: "100%",
        overflow: "auto",
      }}
    // data-theme={mode}
    >

      <Container maxWidth="xl" >

        {waiting_for_input || load_data || is_error ?
          <Box
            
          >
            <RepoSearchInput />
            <WaitForInputView />
          </Box>
          : repo_viewer ?
            <RepositoryViewer />

            : null
        }
      </Container>
    </Box>
  );
}


export { GithubView }


