import { styled } from '@mui/material/styles';


import {  Paper } from "@mui/material";
const MdFileWrapPaper = styled(Paper)(({ theme }) => ({
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    ...theme.typography.body2,
  
  }));

export { MdFileWrapPaper }