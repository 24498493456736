import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FolderIcon from '@mui/icons-material/Folder';
import CloseIcon from '@mui/icons-material/Close';
import { GhRepoTreeContentItem } from '../../../../types/app.types';


function generate(element: React.ReactElement) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));



type DocumentTitleProps = {
    doc: GhRepoTreeContentItem ,
    closeFn:  ()=>void
}
const  DetailsViewDocumentTitle = (props:DocumentTitleProps)=> {
 // const [secondary, setSecondary] = React.useState(false);

  const {
    doc,
    closeFn
  } = props

  return (
    <Box sx={{ flexGrow: 1 }}>
            <List 
            disablePadding
            sx={{
               // background:"green",
               pb:1
              //   
            }}
            >
            <ListItem
                    divider
                  secondaryAction={
                    <IconButton edge="end" aria-label="document"
                        onClick={closeFn}
                    >   <CloseIcon />
                  
                    </IconButton>
                  }
                >

                    <ListItemIcon>
                    <SummarizeIcon />
                    </ListItemIcon>
               
                  <ListItemText
                    primary={doc.path.split('/').pop()}
                    secondary={doc.path}
                  />
                </ListItem>
            </List>
    </Box>
  );
}


export {DetailsViewDocumentTitle}