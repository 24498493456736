import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet } from 'react-router-dom';
import {GhViewDrawer } from './GhViewRightDrawer'
import { GhViewAppBar } from './GhViewAppBar';
 import { DetailsPersistentDrawerLeft } from '../github/repoviewer/DetailsViewLeftDrawer';
import { GhRepoParserMachineContext } from '../github/ghrepomachine';
import { useSelector } from '@xstate/react';


const rightDrawerWidth = 320;
const leftDrawerWidth = 420;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  //height: '100%',
 
  display: 'flex',
  flexDirection: "column",
  // padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -rightDrawerWidth,
//  marginLeft: -leftDrawerWidth,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
  /**
   * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
   * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
   * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
   * proper interaction with the underlying content.
   */
  position: 'relative',
}));



const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export function PersistentDrawerRight() {
  

  const [open, setOpen] = useState(true)

  // useEffect(()=>{
  //   console.log("[CatalogViewLayout start]")
  // })

  return (


    <Box sx={{ display: 'flex', height:"100vh", pb:2, pt:2  }}>
      <CssBaseline />
      <GhViewAppBar open={open} setOpen={setOpen} drawerWidth={leftDrawerWidth}/>
      <DetailsPersistentDrawerLeft  drawerWidth={leftDrawerWidth} />
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      

      </Main>
      <GhViewDrawer open={open} setOpen={setOpen} drawerWidth={rightDrawerWidth} />
    </Box>
   
  );
}