
import { useState } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { useAppContext } from '../../../service/context/app/useAppContext';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { GhRepoParserMachineContext } from "./ghrepomachine";
import { useSelector } from '@xstate/react';

import LinkIcon from '@mui/icons-material/Link';


const RepoSearchInput = () => {
    const svc = GhRepoParserMachineContext.useActorRef()
    const [repo, setRepo] = useState<string>('');
    // //   const svc = GhRepoParserMachineContext.useActorRef()
    // //   const current_doc = useSelector(svc, (state) =>  state.context.current_doc)
    const {
  
      repo: _repo,
      waiting_for_input,
      load_data,
      repo_viewer,
      is_error
  
    } = useSelector(svc, (state) => ({
  
      repo: state.context.repo,
  
      waiting_for_input: state.matches('waiting_for_input'),
      is_error: state.matches('error'),
      load_data: state.matches('load_data'),
      repo_viewer: state.matches('repo_viewer'),
  
    }))



  const handleFetchRepo = () => {
    if (!repo.trim().length) return false

    console.log("handleFetchRepo called", repo.split("/"))
    svc.send({
      type: "EVENTS.REPO.SETUP",
      repo_link: repo
    })
  }

  const handleClearInput = () => {
    console.log("handleClearInput called")
    setRepo(() => {
      svc.send({
        type: "EVENTS.REPO.CLEAR"
      })
      return ""
    })
  }



    return (

        <FormControl fullWidth error={is_error} sx={{ pb: 1 }}>
            <InputLabel htmlFor="outlined-adornment-amount">Github repository</InputLabel>

            <OutlinedInput

                disabled={!waiting_for_input}
                id="outlined-adornment-amount"
                startAdornment={<InputAdornment position="start"><LinkIcon /></InputAdornment>}
                label="Github repository"
                value={repo}
                onChange={(e) => setRepo(e.target.value)}
                endAdornment={
                    <InputAdornment position="end">
                        {/* <SplitButton /> */}
                        {
                            load_data ?
                                <IconButton
                                    color="info"
                                    aria-label="loading"
                                    edge="end"
                                >
                                    <HourglassBottomIcon />
                                </IconButton> :
                                waiting_for_input ?
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleFetchRepo}
                                        //  onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        <ManageSearchIcon />
                                    </IconButton> :

                                    is_error ?
                                        <IconButton
                                            color="error"
                                            aria-label="toggle password visibility"
                                            onClick={handleClearInput}
                                            edge="end"
                                        >
                                            <CloseIcon />
                                        </IconButton> :
                                        null
                        }


                    </InputAdornment>
                }
            />
            {is_error ?
                <FormHelperText id="component-error-text">we're expecting input like this:  /https\:\/\/github\.com\/([\w.-]+)\/([\w.-]+)/</FormHelperText>
                :
                <FormHelperText id="component-help-text">&nbsp;</FormHelperText>}
        </FormControl>
    )
}


export {RepoSearchInput}