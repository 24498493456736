import { useState, useEffect } from "react"
import { SettingsBarCollapsibleWrapper } from './SettingBarCollapsibleWrapper';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import { GhRepoParserMachineContext } from "../ghrepomachine";
import { useSelector } from '@xstate/react';
import { Box } from "@mui/material";
import Divider from '@mui/material/Divider';

import { CatalogViewFlatList } from './FlatList'
import {CatalogViewFlatListGrouped} from './FlatListGrouped'
import { CatalogTreeView } from "./LeftSidebarCatalogTree";
 
const LeftSidebarCatalogMain = (

    // { viewMode, setViewMode } : {
    //     viewMode: "flat" | "tree",
    //     setViewMode: (value: "flat" | "tree") => void,
    // }

  
) => {
    const svc = GhRepoParserMachineContext.useActorRef()
 
    const catalog_tree_display_settings = useSelector(svc, (state) => state.context.catalog_tree_display_settings)
 //   const [viewMode, setViewMode] = useState<'flat' | 'tree'>('tree')
    return (
        <Box
            sx={{
                display:"flex",
                flexDirection:"column",
                height:"100%",
                p:1
            }}
        >
         
             <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="settings-list-subheader"
                disablePadding
                subheader={
                    <ListSubheader component="div" id="settings-list-subheader" sx={{
                    p: 0
                    }}>
                    <SettingsBarCollapsibleWrapper />
                    <Divider />


                    </ListSubheader>
                }
                >
                {catalog_tree_display_settings.viewMode === 'flat' ?
                    // <CatalogViewFlatList />
                    <CatalogViewFlatListGrouped />
                    :
                    <CatalogTreeView />
                }
            </List>
        </Box>
    )
}



export { LeftSidebarCatalogMain }